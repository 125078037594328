import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { AuthState, RootState, FlashState } from './types/States';
import {bindActionCreators, compose, Dispatch} from 'redux';
import {connect} from 'react-redux';
import CardSection from './CardSection';
import Button from './Button';
import * as keys from './common/keys';
import {SubscriptionType} from './types/Users';
import media from 'styled-media-query';
import DiscountablePlanCard from './components/DiscountablePlanCard';
import {logEvent} from 'firebase/analytics';
import {analytics} from './common/firebase';
import * as analyticsHelper from './common/analyticsHelper';
import {DiscountCodeValidationResult, SuccessfulDiscountCodeValidationResult} from './types/Purchasing';
import {getFormattedCurrency} from './helpers/Utils';
import {acceptDiscountCode, acceptPayment, restartOnboarding} from './actions'
import { useCookies } from 'react-cookie'
const PROMO_CODE_COOKIE_NAME = 'am_p_cd'

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.2em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
  ${media.greaterThan("medium")`
    font-size: 1.5em;
  `}
`;
const PaymentConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  ${media.greaterThan("medium")`
      max-width: 750px;
      margin: auto;
    `}
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledCTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.9em;
  ${media.greaterThan("medium")`
        margin-top: 1.3em;
    `}
`;

const StyledPrimaryButton = styled(Button)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
  font-size: 16px;
  ${media.greaterThan("medium")`
    font-size: 1.0em;
    width: unset;
  `}
`;

const StyledSecondaryButton = styled(Button)`
  display: block;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: #FFFFFF;
  color: ${props => props.theme.colors.black};
  border-color: #ADB1B9;
  font-size: 0.7em;
  padding: 0.5em 0.7em;
  border-radius: 0;
  letter-spacing: 1px;
  ${media.greaterThan("medium")`
    font-size: 0.7em;
  `}
`;

const StyledReferralCodeSection = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  background-color: #D8D8D8;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  margin-top: 0.8em;
  padding: 1em 1em;
  width: 80%;
  ${media.greaterThan("medium")`
      margin-top: 1.3em;
      width: 88%;
  `}
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  padding: 0.5em;
  width: 50%;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 14px;
`;

const StyledLegalText = styled.div`
  margin: 20px 20px;
  font-size: 0.8em;
  color: gray;
  ${media.greaterThan("medium")`
    margin: 20px; 100px;
  `}
`

type PaymentConfirmationProps = {
  currentUser: AuthState['currentUser']
  flashError: FlashState['errorMessage'],
  restartOnboarding: typeof restartOnboarding,
  acceptPayment: typeof acceptPayment,
  acceptDiscountCode: typeof acceptDiscountCode,
}

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [referralOrCouponCode, setReferralOrCouponCode] = useState('');
  const [discountCodeValidationResult, setDiscountCodeValidationResult] = useState<DiscountCodeValidationResult>(DiscountCodeValidationResult.NO_OFFER_INVALID_DISCOUNT_CODE);
  const [successfulDiscountCodeValidationResult, setSuccessfulDiscountCodeValidationResult] = useState<SuccessfulDiscountCodeValidationResult | undefined>(undefined)
  const [{am_p_cd : promoCode}] = useCookies([PROMO_CODE_COOKIE_NAME])
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    logEvent(analytics, 'ff_page_view', {
      page_title: 'payment_confirmation'
    });
    analyticsHelper.recordPageView(keys.AmpPagePayment);
  }, []);

  const { currentUser, flashError, acceptDiscountCode } = props

  useEffect(() => {
    if (!currentUser) return
    if (!promoCode) return
    if (flashError) return

    setIsLoading(true);
    acceptDiscountCode(currentUser, promoCode, (discountCodeValidationResult: DiscountCodeValidationResult) => {
      setDiscountCodeValidationResult(discountCodeValidationResult);

      if (!discountCodeValidationResult.includes('NO_OFFER')) {
        setSuccessfulDiscountCodeValidationResult(discountCodeValidationResult as SuccessfulDiscountCodeValidationResult)
      } else {
        setSuccessfulDiscountCodeValidationResult(undefined)
      }

      setReferralOrCouponCode('');
      setIsLoading(false);
      window.scrollTo(0, 0);
    });
  }, [currentUser, promoCode, acceptDiscountCode, flashError]);

  if (!currentUser) {
    return null
  }

  if (!currentUser.selectedPlan || currentUser.selectedPlan === 'INTRO') {
    props.restartOnboarding(currentUser.id);
    return null
  }

  const handleReferralCodeChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const currentString = evt.target.value;
    setReferralOrCouponCode(currentString.toUpperCase());
  };

  const isMonthly = () => {
    return ['BASIC', 'GOLD', 'PLATINUM'].includes(currentUser.selectedPlan as string)
  }

  const freeTrialValidationResult = (process.env.REACT_APP_NEW_USER_FREE_TRIAL === 'true' && !currentUser.stripeSubscriptionId && !isMonthly()) ? DiscountCodeValidationResult.SEVEN_DAY_FREE_TRIAL : undefined


  const getInitialChargeAmount = (selectedPlan: Exclude<SubscriptionType, null>): number => {
    switch(discountCodeValidationResult) {
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_1_MONTH: {
        return keys.plans[selectedPlan].fiftyOffUnitPriceUSD
      }
      case DiscountCodeValidationResult.HUNDRED_PERCENT_OFF_1_MONTH: {
        return 0
      }
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_6_MONTHS: {
        return keys.plans[selectedPlan].fiftyOffUnitPriceUSD
      }
      case DiscountCodeValidationResult.THIRTY_PERCENT_OFF_1_MONTH: {
        return keys.plans[selectedPlan].thirtyOffUnitPriceUSD
      }
      case DiscountCodeValidationResult.TEN_PERCENT_OFF_1_MONTH: {
        return keys.plans[selectedPlan].tenOffUnitPriceUSD
      }
      default: {
        return keys.plans[selectedPlan].unitPriceUSD
      }
    }
  }
  const getLegalText = (selectedPlan: Exclude<SubscriptionType, null>, initialChargeAmount: number): string => {
    switch(discountCodeValidationResult) {

      case DiscountCodeValidationResult.THIRTY_PERCENT_OFF_1_MONTH:
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_1_MONTH:
      case DiscountCodeValidationResult.TEN_PERCENT_OFF_1_MONTH:
      case DiscountCodeValidationResult.HUNDRED_PERCENT_OFF_1_MONTH: {

        return `You agree to an automatically recurring billing subscription. You will be charged\
         ${getFormattedCurrency('en', 'USD', initialChargeAmount)} USD upon confirmation. Your subscription will automatically renew\
         every ${isMonthly() ? 'month' : 'year'} at a rate of $${keys.plans[selectedPlan].unitPriceUSD} USD plus applicable\
         tax using the payment method provided here. You may manage your subscription or disable\
         auto-renewal from within the dashboard. Refunds are not provided for the unused portion\
         of any term, and any unused portion of the discounted period will\
         be forfeited when you change plans.`
      }
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_6_MONTHS: {
        if (isMonthly()) {
          return `You agree to an automatically recurring billing subscription. You will be charged\
         ${getFormattedCurrency('en', 'USD', initialChargeAmount)} USD upon confirmation. Your subscription will automatically renew\
         at a rate of ${getFormattedCurrency('en', 'USD', keys.plans[selectedPlan].fiftyOffUnitPriceUSD)} USD plus applicable tax using the\
         payment method provided here for the first six months.\
         After the first six months, your subscription will automatically renew at the rate of\
         $${keys.plans[selectedPlan].unitPriceUSD} USD plus applicable tax using\
         the payment method provided here. You may manage your subscription or disable auto-renewal\
         from within the dashboard. Refunds are not provided for the unused portion of any term. Changing\
         plans during the initial discount period will forfeit the discount.`
        } else {
          return `You agree to an automatically recurring billing subscription. You will be charged\
         ${getFormattedCurrency('en', 'USD', initialChargeAmount)} USD upon confirmation. Your subscription will automatically renew\
         every year at a rate of $${keys.plans[selectedPlan].unitPriceUSD} USD plus applicable\
         tax using the payment method provided here. You may manage your subscription or disable\
         auto-renewal from within the dashboard. Refunds are not provided for the unused portion\
         of any term.and any unused portion of the discounted period will\
         be forfeited when you change plans.`
        }
      }
      default: {
        if (freeTrialValidationResult === DiscountCodeValidationResult.SEVEN_DAY_FREE_TRIAL) {
          return `You agree to an automatically recurring billing subscription. You will be charged\
         ${getFormattedCurrency('en', 'USD', 0)} USD upon confirmation. At the end of the 7th day of your free trial, you \
         will be charged ${getFormattedCurrency('en', 'USD', initialChargeAmount)} USD. Your subscription will automatically renew\
         every ${isMonthly() ? 'month' : 'year'} at a rate of $${keys.plans[selectedPlan].unitPriceUSD} USD plus applicable\
         tax using the payment method provided here. You may manage your subscription or disable\
         auto-renewal from within the dashboard. Refunds are not provided for the unused portion\
         of any term.`
        }
        return `You agree to an automatically recurring billing subscription. You will be charged\
         ${getFormattedCurrency('en', 'USD', initialChargeAmount)} USD upon confirmation. Your subscription will automatically renew\
         every ${isMonthly() ? 'month' : 'year'} at a rate of $${keys.plans[selectedPlan].unitPriceUSD} USD plus applicable\
         tax using the payment method provided here. You may manage your subscription or disable\
         auto-renewal from within the dashboard. Refunds are not provided for the unused portion\
         of any term.`
      }
    }
  }

  const handleReferralCodeSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (!referralOrCouponCode) {
      return
    }
    setIsLoading(true);
    acceptDiscountCode(currentUser, referralOrCouponCode, (discountCodeValidationResult: DiscountCodeValidationResult) => {
      setDiscountCodeValidationResult(discountCodeValidationResult);

      if (!discountCodeValidationResult.includes('NO_OFFER')) {
        setSuccessfulDiscountCodeValidationResult(discountCodeValidationResult as SuccessfulDiscountCodeValidationResult)
      } else {
        setSuccessfulDiscountCodeValidationResult(undefined)
      }

      setReferralOrCouponCode('');
      setIsLoading(false);
      window.scrollTo(0, 0);
    });
  };

  const handlePaymentSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    logEvent(analytics, 'ff_button_click', {
      name: `payment_submit`
    });
    analyticsHelper.recordClick(keys.AmpElementSubmitPaymentButton);
    props.acceptPayment(stripe, elements, cardElement, currentUser, () => {
      setIsLoading(false);
    }, successfulDiscountCodeValidationResult ?? freeTrialValidationResult ?? undefined);
  };

  const initialChargeAmount = getInitialChargeAmount(currentUser.selectedPlan)

  return (
      <>
        <PaymentConfirmationContainer>
          <StyledHeading>Enter payment details</StyledHeading>

          <StyledForm onSubmit={handlePaymentSubmit}>
            <DiscountablePlanCard
              selectedPlan={currentUser.selectedPlan}
              discountCodeValidationResult={successfulDiscountCodeValidationResult ?? freeTrialValidationResult ?? DiscountCodeValidationResult.NO_OFFER_INVALID_DISCOUNT_CODE}
            />
            <CardSection/>
            <StyledCTAContainer>
              <StyledPrimaryButton disabled={isLoading} onClick={handlePaymentSubmit}>submit</StyledPrimaryButton>
            </StyledCTAContainer>
          </StyledForm>
          <StyledForm>
            <StyledReferralCodeSection>
              <StyledInput
                  type="text"
                  value={referralOrCouponCode}
                  onChange={handleReferralCodeChange}
                  placeholder="DISCOUNT CODE"/>
              <StyledSecondaryButton disabled={isLoading} onClick={handleReferralCodeSubmit}>apply</StyledSecondaryButton>
            </StyledReferralCodeSection>
          </StyledForm>
        </PaymentConfirmationContainer>
        <StyledLegalText>
          {getLegalText(currentUser.selectedPlan, initialChargeAmount)}
        </StyledLegalText>
      </>
  );
};

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.auth.currentUser,
    flashError: state.flash.errorMessage,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    restartOnboarding,
    acceptDiscountCode,
    acceptPayment,
  }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(PaymentConfirmation);
