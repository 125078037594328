import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {SoundCloudProfile, SubscriptionType} from '../types/Users';
import * as keys from '../common/keys';
import {DiscountCodeValidationResult, SuccessfulDiscountCodeValidationResult} from "../types/Purchasing";
import {CreateOrUpdateLeadResponse, ViewingTokenValidationResult} from '../types/StemMastering'
const ENCRYPTED_KEY = 'Zm9sbG93ZXItZ3Jvd3RoLXllYWg=';

interface BodyParams {
  [key: string]: any;
}

export default class ApiHelper {
    axiosInstance: AxiosInstance;

    constructor(userId: string) {
        // TODO: switch out atob and btoa to use: https://stackabuse.com/encoding-and-decoding-base64-strings-in-node-js/
        this.axiosInstance = axios.create({
            baseURL: `${keys.ApiUrl}/private`,
            headers: {
                'Authorization': `Basic ${btoa(userId + ':' + atob(ENCRYPTED_KEY))}`
            }
        })
    }

    createCustomToken = async(uid: string, password: string): Promise<string> => {
        try {
            const response = await this.axiosInstance.post<{ token: string }>('/create-custom-token', {
                uid,
                password,
            })
            const { token } = response.data
            return Promise.resolve(token)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    createUser = async(scUserUrl: string, email: string, password: string, firstName: string): Promise<string> => {
        try {
            const response = await this.axiosInstance.post('/create-user', {
                scUserUrl,
                email,
                password,
                firstName,
            })
            const { ffUserId } = response.data
            return Promise.resolve(ffUserId)
        } catch(error) {
            return Promise.reject(error)
        }
    }

    getSCProfile = async (scId: string): Promise<SoundCloudProfile> => {
        try {
            const response = await this.axiosInstance.get(`/sc-data?scId=${scId}`);
            const scProfile: SoundCloudProfile = response.data;
            return Promise.resolve(scProfile);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    scheduleFollows = async (url: string): Promise<number> => {
        const response = await this.axiosInstance.put('/schedule-follows', {
            url,
        });
        const { numSourced } = response.data;
        return Promise.resolve(numSourced);
    };

		connectSoundCloudAccount = async(code: string, state?: string): Promise<void> => {
				await this.axiosInstance.put('/connect-sc-account', {
					code,
          state,
				});
				return Promise.resolve();
		}

    signIntoSoundCloud = async (scPassword: string): Promise<void> => {
        await this.axiosInstance.put('/authenticate-sc-account', {
            scPassword,
        });
        return Promise.resolve();
    };

    initiateLoginFlow = async (): Promise<{ redirectUrl: string}> => {
        const response = await this.axiosInstance.get('/authorize')
        const { redirectUrl } = response.data
        return {
            redirectUrl,
        }
    }

    validateDiscountCode = async (referralOrCouponCode: string, selectedPlan: Exclude<SubscriptionType, null>): Promise<DiscountCodeValidationResult> => {
      const response = await this.axiosInstance.put<{validationResult: DiscountCodeValidationResult}>('/validate-discount-code', {
        referralOrCouponCode,
        selectedPlan
      });
      const { validationResult } = response.data;

      return Promise.resolve(validationResult);
    };

    requestSCPasswordReset = async (): Promise<Array<string>> => {
      const response = await this.axiosInstance.put('/sc-password-reset');
      const addresses = response.data;

      return Promise.resolve(addresses);
    };

    createOrUpdateSubscription = async (paymentMethodId: string, email: string, planName: SubscriptionType, discountCodeValidationResult?: SuccessfulDiscountCodeValidationResult): Promise<any> => {
        let body: BodyParams = {
          email,
          paymentMethodId,
          planName,
        };
        if (discountCodeValidationResult) {
          body = {
            ...body,
            discountCodeValidationResult
          };
        }
        const response = await this.axiosInstance.put('/stripe/subscription', body);
        const {subscription} = response.data;
        return Promise.resolve(subscription);
    };

    changeSubscriptionPlan = async (plan: SubscriptionType): Promise<any> => {
        const response = await this.axiosInstance.put('/stripe/subscription/plan', {
            plan,
        });
        const {subscription} = response.data;
        return Promise.resolve(subscription);
    };

    submitFeedback = async (feedback: string, isCanceling: boolean): Promise<void> => {
        await this.axiosInstance.put('/feedback', {
            feedback,
            isCanceling,
        });
        return Promise.resolve();
    };

    setAutoRenew = async (autoRenew: boolean): Promise<any> => {
        const response = await this.axiosInstance.put('/stripe/subscription/auto-renew', {
            autoRenew: autoRenew,
        });
        const {subscription} = response.data;

        return Promise.resolve(subscription);
    };

    retrieveSubscription = async (subscriptionId: string): Promise<any> => {
        const response = await this.axiosInstance.post('/stripe/retrieve-subscription', {
            subscriptionId,
        });
        const { subscription } = response.data;
        return Promise.resolve(subscription);
    };

    requestStemMasteringReplay = async (firstName: string, email: string): Promise<string> => {
        try {
            const response = await this.axiosInstance.post<CreateOrUpdateLeadResponse>('/request-stem-mastering', {
                email,
                firstName,
            })
            const { leadId } = response.data
            return Promise.resolve(leadId)
        } catch(error) {
            return Promise.reject(error)
        }
    };

    verifyArtistEligibility = async (leadId: string, scUserUrl: string): Promise<void> => {
        try {
            await this.axiosInstance.post<void>('/verify-artist-eligibility', {
                leadId,
                scUserUrl,
            })
        } catch(error) {
            return Promise.reject(error)
        }
    }

    static requestPasswordResetEmail = (email: string): Promise<AxiosResponse<any>> => {
        return axios.post(keys.ApiUrl + '/request-password-reset', {
            email,
        });
    };

    static resetPassword = async (password: string, token: string): Promise<AxiosResponse<any>> => {
        return axios.put(keys.ApiUrl + '/reset-password', {
            password,
            token,
        });
    };

    static validateViewingToken = async (viewingToken: string): Promise<ViewingTokenValidationResult> => {
        const response = await axios.put<{validationResult: ViewingTokenValidationResult}>(keys.ApiUrl + '/validate-viewing-token', {
            viewingToken,
        });
        const { validationResult } = response.data;
        return Promise.resolve(validationResult)
    }
}
